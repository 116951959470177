// Gatsby supports TypeScript natively!
import React from "react"
import { PageProps, Link, graphql } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"
import { isMobile } from "react-device-detect"
//import "slick-carousel/slick/slick.css"
//import "slick-carousel/slick/slick-theme.css"
//import Slider from "react-slick"
import Waflash from "../components/waflash"

type Data = {
  site: {
    siteMetadata: {
      title: string
    }
  }
  allMarkdownRemark: {
    edges: {
      node: {
        excerpt: string
        frontmatter: {
          title: string
          date: string
          description: string
          thumbnail: {
            publicURL: string
          }
          swf: {
            publicURL: string
          }
        }
        fields: {
          slug: string
        }
      }
    }[]
  }
}

const Card = styled.article`
  position: relative;
  //display: grid;
  //background-color: white;
  //border-radius: 9px;
  border: 1px solid #d3d6e7;
  //box-shadow: 0 0 30px #d5d5d5;
  overflow: hidden;
  line-height: 1.5;
  &:hover {
    color: #381696;
  }
  margin: 2px;
`
const CardTitle = styled.div`
  font-size: 0.75rem;
  margin-bottom: 0.3rem;
  //margin-block-start: 0.83em;
  margin-block-end: 0.3em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  //font-weight: bold;
`

const CardDesc = styled.div`
  font-size: .62rem;
  //transform: scale(0.83);
  font-weight: 100;
  margin-botton: 0;
  margin-block-end: 0.3em;
`

const CardLikes = styled.div`
  position: absolute;
  top: 125px;
  right: 7px;
  font-size: 0.8rem;
  padding: 1px 4px;
  background-color: black;
  color: white;
`

// Galindo, Kavoon, 
// 168x94



const PostCard = (props) => {
  const node = props.post;
  const title = node.frontmatter.title || node.fields.slug
  const o = node.frontmatter;
  return (
    <Card>
      <a style={{ boxShadow: `none` }} href={node.fields.slug}>
        <img style={{ marginBottom: 0 }} src={o.thumbnail.publicURL} width={175} height={100} />
      </a>
      {/* <CardLikes><span>100</span></CardLikes> */}
      <div style={{ paddingLeft: "4px", marginTop: "0" }}>
        <CardTitle>
          <a style={{ boxShadow: `none` }} href={node.fields.slug}>
            {title}
          </a>
        </CardTitle>
        <CardDesc>by {o.author}</CardDesc>
      </div>
    </Card>
  )
}

const BlogIndex = ({ data, location }: PageProps<Data>) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges

  const Posts = posts.map(({node}) => <PostCard key={node.fields.slug} post={node}></PostCard>)

  const cardContainerStyle = css`
    display: grid;
    grid-gap: 2px;
    margin-top: 12px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    @media (max-width: 640px) {
      grid-template-columns: 1fr 1fr 1fr;
    }
    @media (max-width: 420px) {
      grid-template-columns: 1fr 1fr;
    }
  `

  /*
  {
    centerMode: !0,
    mobileFirst: !0,
    autoplay: !1,
    prevArrow: i.find(".carousel-arrow-left"),
    nextArrow: i.find(".carousel-arrow-right"),
    initialSlide: curslide,
    waitForAnimate: !0,
    swipeToSlide: !0,
    speed: 200,
    responsive: [{
        breakpoint: 425,
        settings: {
            slidesToShow: 3
        }
    }, {
        breakpoint: 768,
        settings: {
            slidesToShow: 3
        }
    }, {
        breakpoint: 1080,
        settings: {
            slidesToShow: 5
        }
    }]
  }
  */

  const settingsSlider = {
    dots: false,
    //infinite: true,
    //centerMode: true,
    //centerPadding: '40px',
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    //arrows: true,
    //autoplay: true,
    //autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        }
      },
      {
        breakpoint: 360,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        }
      },
    ],
  }

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="WebAssembly Flash"/>

      <Waflash disable={isMobile} width={'100%'} height={'480px'} />

      <div style={{ margin: '1.0rem', fontSize: '1.45rem', fontFamily: "Noto Sans KR", textAlign: "center", lineHeight: '1.5' }}>
        <strong style={{ color: '#6545f0' }}>WAFlash is a WebAssembly AS2/AS3 Flash compatible player</strong>
        <p style={{ fontSize: '1.2rem' }}>developed with HTML5, WebGL, WebAssembly and Emscripten.</p>
      </div>

      <h4 style={{ marginBottom: '0.7rem' }}>Flash Demo</h4>

      {/* <Slider {...settingsSlider}>
        {posts.map(({node}) => <PostCard key={node.fields.slug} post={node}></PostCard>)}
      </Slider> */}

      <div css={cardContainerStyle}>
        {Posts}
      </div>

    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___priority, frontmatter___date], order: [ASC, DESC] }, filter: {frontmatter: {hidden: {ne: true}}}) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            author
            authorUrl
            from
            fromUrl
            swf {
              publicURL
            }
            thumbnail {
              publicURL
            }
          }
        }
      }
    }
  }
`
